@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:wght@300;400;500;700&display=swap');

:root{
  --font-color: #F8F8F8;
  --bg-color: #404040;
  --border-color: #C1A260;
  --border-color-secondary: #C4C4C4;
  --font-color-secondary: #404040;
  --legend-color-green: #B4DA82;
  --legend-color-yellow: #FAD976;
  --legend-color-orange: #F09D6F;
  --legend-color-red: #EB3C38;
}

* {
  box-sizing: border-box;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

body {
  font-family: 'Red Hat Text', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--font-color);
  font-size: 1rem;
  background-color: var(--bg-color);
}

.body-small {
  font-family: 'Red Hat Text', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--font-color);
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: 'Red Hat Text', sans-serif;
  font-weight: 700;
  color: var(--font-color);
  font-size: 2.4rem;
}

h2 {
  color: var(--font-color);
  font-size: 1.3rem;
  font-family: 'Red Hat Text', sans-serif;
  font-weight: 700;
}

h3 {
  color: var(--font-color);
  font-size: 1rem;
  text-transform: uppercase;
  font-family: 'Red Hat Text', sans-serif;
  font-weight: 700;
  margin-block-start: 1.4em;
  margin-block-end: 0.3em;
}

.h3-twothird {
  color: var(--font-color);
  text-transform: uppercase;
  font-size: 19px;
  font-family: 'Red Hat Text', sans-serif;
  font-weight: 300;
  margin-block-start: 0px;
}



.header-main {
  width: 100%;
  /* padding-left: 60px; */
  padding-top: 5rem;
  float: left;
}
.header-right {
  display: none;
  width: 100%;
  float: left;
}

.header-top {
  display: block;
  width: 100%;
  float: left;
  text-align: center;
}

.logo-center {
  width: 25%;
  margin-top: 8rem;
  margin-bottom: 5rem;
}

@media only screen and (max-width: 249px) {
  .logo-center {
    width: 10rem;
  }
}

.link-container{
  display: flex;
  gap: 4rem;
}

@media only screen and (max-width: 480px) {
  .link-container {
    flex-wrap: wrap;
    gap: 1rem;
  }
}

@media only screen and (min-width: 250px) {
  .logo-center {
    width: 14rem;
  }
}

@media only screen and (min-width: 400px) {
  .logo-center {
    width: 17rem;
  }
}

@media only screen and (min-width: 768px) {
  .main-col {
    width: 60%;
    margin: auto;
  }

  .header-main {
    width: 80%;
    padding-top: 1rem;
  }
  .header-right {
    display: block;
    width: 20%;
  }
  .header-top {
    display: none;
    width: 100%;
    float: left;
  }
}

.logo-right {
  padding-top: 20px;
  width: 14rem;
}

.img-fullsize {
  padding: 20px;
  max-width: 100%;
}

.img-fullsize-small {

  padding: 3rem 1rem;
  max-width: 90%;
}

@media only screen and (min-width: 968px) {
  .img-fullsize {
    padding: 20px;
    max-width: 80%;
  }
  .img-fullsize-small {
    padding: 20px;
    max-width: 65%;
  }
}

.m-auto {
  margin: auto;
  text-align: center;
}

.to-result-pers{
  padding-top: 4rem;
  text-align: left;
  padding-left: 18px;
}

.content {
  width: 100%;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

.font-colored{
  color: var(--border-color)
}

.h-align {
  margin: auto;
  max-width: 100%;
  padding-top: 15px;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .h-align {
    margin: auto;
    max-width: 60%;
    padding: 10px;
    text-align: center;
  }
}

input[type="text"], input[type="password"] {
  font-size: 1rem;
  outline: none;
  color: var(--font-color);
  border: 1px solid var(--border-color);
  background-color: var(--bg-color);
  padding: 12px 18px;
  box-sizing: border-box;
  margin: 2px;
}

input[type="checkbox"] {
  background-color: var(--border-color);
  accent-color: var(--border-color);
  color: var(--bg-color);
  outline: 1px solid var(--border-color);
  cursor: pointer;
  opacity: 1;
}

#footer{
  margin-top: 10rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#footer > div{
  flex-basis: 33%;
}

#footer #logo-container{
  text-align: left;
}

#footer a{
  font-weight: normal !important;
}

#footer-logo{
  width: 60%;
}

#footer-nav{
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}

#footer-nav div{
  text-align: left;
  padding: 0.3rem 0;
}

#footer #footer-socials .social{
  width: 1.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

#footer #footer-socials{
  display: flex;
  justify-content: center;
}

#footer #footer-socials .social img{
  width: 100%;
  filter: invert(100%);
}

#main-content{
  min-height: 68vh;
}


@media only screen and (max-width: 768px) {
  #footer{
    margin-top: 10rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }

  #footer > div{
    flex-basis: 100%;
    padding: 1rem 0;
  }

  #footer #logo-container{
    width: 60%;
    flex-wrap: wrap;
    text-align: center;
  }

  #footer-logo{
    width: 40%;
    margin:auto;
  }
  #footer-nav div{
    text-align: center;
    width: 100%;
  }

  #footer-nav{
    display: flex;
    flex-wrap: wrap;
  }
}

input[type="checkbox"]:not(:checked){
  position: relative;
}

input[type="checkbox"]:not(:checked)::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-color);  /* Hier kannst du die gewünschte Hintergrundfarbe einstellen */
}

input:disabled {
  filter: grayscale(0.9);
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: var(--font-color);
}
::-moz-placeholder { /* Firefox 19+ */
  color: var(--font-color);
}
:-ms-input-placeholder { /* IE 10+ */
  color: var(--font-color);
}
:-moz-placeholder { /* Firefox 18- */
  color: var(--font-color);
}

button {
  font-weight: bold;
  background-color: var(--bg-color);
  border: none;
  color: var(--border-color);
  font-size: 17px;
  padding: 12px 18px;
  font-family: 'Red Hat Text', sans-serif;
}

button:hover:enabled {
  /*border: 2px solid #129BDB;*/
  /*color: #F27C00;*/
  /*padding: 12px 18px;*/
  cursor:pointer;
}

button:active:enabled {
  background-color: var(--border-color);
  color: white;
  padding: 12px 18px;
}

button:disabled {
  /*color: #5D5C5C;*/
  /*border-bottom: 2px solid #5D5C5C;*/
  /*padding: 12px 18px;*/
}

#footer a {
  color: var(--font-color);
  font-size: 12px;
  font-weight: 500;
  /*padding-left: 10px;*/
  padding-right: 10px;
  text-transform: uppercase;
}
#footer a:link {
  text-decoration: none;
}
#footer a:visited {
  text-decoration: none;
}
#footer a:hover {
  text-decoration: underline;
}
#footer a:active {
  text-decoration: underline;
}

label.container span:last-child{
  margin-left: 0 !important;
}

#body a {
  font-size: 0.90rem;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--border-color);
}
/*#body a:before {*/
/*  content:'\2B95\00a0';*/
/*}*/
.result-box .halfdiv{
  border: 1px solid var(--border-color-secondary);
  min-height: 16rem;
  margin: 0.5rem;
}

.table-fstil{
  margin: 1rem 0rem;
}

@media only screen and (max-width: 768px) {
  .result-box .halfdiv{
    min-height: auto;
  }
}

#body a:link {
  text-decoration: none;
}
#body a:visited {
  text-decoration: none;
}
#body a:hover {
  text-decoration: underline;
}
#body a:active {
  text-decoration: underline;
}

/* Survey JS */

.btn-lg {
  background-color: transparent;
  outline: none;
  border: none;
  color: var(--border-color) !important;
  font-size: 17px !important;
  font-family: 'Red Hat Text', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  margin: auto !important;
  border-radius: 0px !important;
  padding: 12px 18px !important;
  cursor:pointer;
}

.btn-lg:hover:enabled {
  background-color: var(--border-color);
  color: white !important;
  cursor:pointer;
  /*padding: 12px 18px !important;*/
}

.btn-lg:active:enabled {
  background-color: var(--border-color) !important;
  color: white !important;
  padding: 12px 18px !important;
}

.btn-lg:disabled {
  color: #5D5C5C;
  border-bottom: 2px solid #5D5C5C;
  padding: 12px 18px;
}

.sv_hidden {
  display: none;
}

.sv_page_title {
  color: var(--font-color);
  font-size: 1rem;
  font-family: 'Red Hat Text', sans-serif;
}

.sv_q_title {
  color: var(--font-color);
  margin-block-end: 0.5em;
  font-weight: 300;
  font-size: 19px;
  font-family: 'Red Hat Text', sans-serif;
}

.cell-content-outside{
  display: flex;
  justify-content: space-evenly;
}

.sv_next_btn {
  float: right;
}

.sv_prev_btn {
  float: left;
}

.sv_complete_btn {
  float: right;
}

/* Radio Button */

/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border: 1px solid var(--border-color);
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: var(--border-color-secondary);
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: var(--border-color);
}

.sv_q_erbox {
  background-color: red !important;
  color: white !important;
  margin: 10px;
  padding: 3px;
  text-align: center;
  font-weight: bold;
}

.table-selbst tr td{
  padding-bottom: 1rem;
}

.table-selbst tr th{
  padding-bottom: 1.2rem;
}

.table-selbst tr td:nth-child(3){
  padding-left: 5rem;
}
.table-selbst tr th:nth-child(3){
  padding-left: 5rem;
}

.pers-result-box{
  display: flex;
}

@media only screen and (max-width: 768px) {
  .pers-result-box{
    flex-wrap: wrap;
  }
}

.pers-table th{
  padding: 0.5rem 4rem 0.5rem 0;
}

.pers-table td{
  padding: 0.3rem 4rem 0.3rem 0;
}

.pers-table td:nth-child(2){
  text-align: center;
}

table {
  border-collapse: collapse;
}

th, td {
  /*padding: 5px;*/
  text-align: left;
  /* border: 1px solid black; */
}

td, tr {
  text-align: left;
}

th {
  font-weight: 700;
  color: var(--border-color);
  text-transform: uppercase;
}

/* tr:nth-child(even) {background-color: #CBE0EC;}
tr:nth-child(odd) {background-color: #E3EFF4;} */

.table-much-text {
  text-align: left;
  vertical-align: middle;
}

.table-selbst-result tr th{
  padding-bottom: 1.2rem;
}
.table-selbst-result tr td{
  padding-bottom: 0.8rem;
}

.table-selbst-result tr th:nth-child(2), .table-selbst-result tr td:nth-child(2){
  padding-left: 3rem;
}

.table-selbst tr td.table-much-text{
  width: 40%;
}

.halfcol {
  width: 95%;
  float: left;
  padding-left: 20px;
  padding-right: 20px;
}

.thirdcol {
  width: 100%;
  float: left;
}

.twothirdcol {
  width: 100%;
  float: left;
}

.halfcol-right {
  width: 95%;
  float: left;
  padding-left: 20px;
  padding-right: 20px;
}


@media only screen and (max-width: 768px) {
  .halfcol-right {
    width: 95%;
    float: left;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media only screen and (min-width: 768px) {
  .table-fstil td {
    padding: 0.1rem;
  }
}

.table-r-align {
    width: 100%;
}

.table-r-align td:nth-child(2), .table-r-align th:nth-child(2){
  text-align: center;
}

.text-col {
  width: 95%;
  float: left;
  padding-left: 20px;
}

@media only screen and (min-width: 768px) {
  .halfcol {
    width: 45%;
    float: left;
    padding: 3rem;
  }

  .text-col{
    float: left;
    padding: 3rem;
  }

  .thirdcol {
    width: 270px;
    float: left;
  }

  .halfcol-right {
    width: 45%;
    float: left;
    padding: 3rem;
    text-align: right;
  }
}

@media only screen and (min-width: 1150px) {
  .twothirdcol {
    width: 60%;
    float: left;
  }
}

.result-box {
  /*border-style: dotted;*/
  /*border-width: 1px;*/
  /*border-color: var(--border-color);*/
  /*padding-bottom: 40px;*/
  /*padding-left: 40px;*/
  /*padding-right: 40px;*/
}

.sv_q_rating_item .sv_q_rating_item_text:hover {
  border: 1px solid var(--border-color) !important;
  }

.sv_q_rating_item_text:hover {
  border: 1px solid var(--border-color) !important;
  }

.sv_main .sv_container .sv_body .sv_p_root .sv_q_title {
  font-size: 19px;
  font-weight: 300;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_page_title {
  font-weight: 300;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type="radio"], .sv_main .sv_container .sv_body .sv_p_root .sv_q input[type="checkbox"] {
  margin: 0;
  margin-right: 0.55em;
  width: 1.2em;
  height: 1.2em;
  vertical-align: middle;
  font-size: 1em;
  margin-top: -0.1em;
}

.sv-visuallyhidden {
  position: absolute;
  overflow-x: hidden;
  overflow-y: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  }

  .sv_main .sv_q_rating_item.active .sv_q_rating_item_text {
  background-color: var(--border-color) !important;
  border-color: var(--border-color) !important;
  color: black;
}


/*.sv_p_root > .sv_row:nth-child(odd) {*/
/*  background-color: white !important;*/
/*}*/
/*.sv_p_root > .sv_row:nth-child(even) {*/
/*  background-color: white !important;*/
/*}*/

.sv_p_root > .sv_row {
  border-bottom: 0px !important;
}

.sv_main .sv_body {
  background-color: white;
  border-top: 0px !important;
  }

.datenschutz h3{
  color: var(--border-color) !important;
  text-transform: none;
}

.chart {
  max-width: 1000px;
  margin: auto;
  display: block;
  padding: 1rem 0;
}

@media only screen and (max-width: 768px) {
  .chart {
    display: none;
  }
}



.sortable-count {
  width: 160px;
  margin:5px;
  padding:10px;
}

.sortable-count-td {
  width: 50px;
}

.sortable-result-td {}

.sortable-count span{
  padding-right: 1rem;
}

.sjs-sortablejs-item {
  color: black !important;
  background-color: var(--border-color) !important;
  font-family: 'Red Hat Text', sans-serif !important;
  min-width: 10rem;
}

.sjs-sortablejs-result {
  border: 0px !important;
  /*background-color: white !important;*/
}

.sv_q_rating_item.hover .sv_q_rating_item_text {
  background-color: var(--border-color) !important;
  border-color: var(--border-color) !important;
  color: black;
}

.sv_q_rating_item.active .sv_q_rating_item_text {
  background-color: var(--border-color) !important;
  border-color: var(--border-color) !important;
  color: black;
}

.sv_q_rating_item .sv_q_rating_item_text:hover {
  background-color: var(--border-color) !important;
  border: 1px solid var(--border-color) !important;
  cursor:pointer;
  color: black;
}

.sv_q_rating_item_text {
  display: inline-block;
  min-width: 2em;
  padding: 0.1em 0.4em;
  margin-left: -1px;
  text-align: center;
}

.sv_q_rating_item_text {
  border: 1px solid var(--border-color);
}

.sv_nav {
  /*padding: 1em 0;*/
}

/* Datepicker */
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  padding: 3px;
  font-size: 15px;
}

.MuiIconButton-root {
  padding: 12px !important;
  border-bottom: none !important;
}

.MuiIconButton-root:hover {
  border: none !important;
  padding: 12px !important;
  border-bottom: none !important;
}


.sv_q_rating_max_text{
  padding-left: 0.8em;
}

.sv_q_rating_min_text{
  padding-right: 0.8em;
}


.MuiTypography-h6 {
  color: var(--font-color);
  font-size: 19px;
  text-transform: uppercase;
  font-family: 'Red Hat Text', sans-serif;
  font-weight: 300;
}

.grid-center span {
  white-space: nowrap;
}

.legend-orange{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--legend-color-orange);
  display: inline-block;
  margin-right: 0.5rem;
}

.legend-yellow{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--legend-color-yellow);
  display: inline-block;
  margin-right: 0.5rem;
}

.legend-red{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--legend-color-red);
  display: inline-block;
  margin-right: 0.5rem;
}

.legend-green{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--legend-color-green);
  display: inline-block;
  margin-right: 0.5rem;
}

.grid-center{
  display: inline-grid;
  grid-template-columns: auto 1fr;
}

.grid-spacing span:first-child{
  min-width: 2rem;
  display: inline-block;
}
